import { AfterViewInit, OnInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { get } from 'scriptjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'ekhlas-app';

  constructor(private _Router: Router) {

  }

  ngOnInit(): void {
    this._Router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngAfterViewInit(): void {
  }
}
