<div class="custom-cursor__cursor"></div>
<div class="custom-cursor__cursor-two"></div>





<div class="preloader">
  <div class="preloader__image"></div>
</div>
<!-- /.preloader -->


<div class="page-wrapper">
  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-footer></app-footer>
</div><!-- /.page-wrapper -->


<div class="mobile-nav__wrapper">
  <div class="mobile-nav__overlay mobile-nav__toggler"></div>
  <!-- /.mobile-nav__overlay -->
  <div class="mobile-nav__content">
    <span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

    <div class="logo-box">
      <a href="#" aria-label="logo image"><img src="assets/images/resources/logo-2.png" width="122" alt="" /></a>
    </div>
    <!-- /.logo-box -->
    <div class="mobile-nav__container"></div>
    <!-- /.mobile-nav__container -->

    <ul class="mobile-nav__contact list-unstyled">
      <li>
        <i class="fa fa-envelope"></i>
        <a href="mailto:info@elekhlascotton.com">info@elekhlascotton.com</a>
      </li>
      <li>
        <i class="fa fa-phone-alt"></i>
        <a href="tel:2034299251">+2 (03) 4299251</a>
      </li>
    </ul><!-- /.mobile-nav__contact -->
    <div class="mobile-nav__top">
    </div><!-- /.mobile-nav__top -->



  </div>
  <!-- /.mobile-nav__content -->
</div>
<!-- /.mobile-nav__wrapper -->