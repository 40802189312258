<header class="main-header-two">
    <div class="main-header-two__inner">
        <div class="main-header-two__top">
            <div class="container">
                <div class="main-header-two__top-inner">
                    <div class="main-header-two__logo">
                        <a href="#"><img src="assets/images/resources/logo-2.png" alt=""></a>
                    </div>
                    <div class="main-header-two__top-left">
                        <ul class="list-unstyled main-header-two__contact-list">
                            <li>
                                <div class="icon">
                                    <i class="icon-email"></i>
                                </div>
                                <div class="text">
                                    <p><a href="mailto:info@elekhlascotton.com">info@elekhlascotton.com</a></p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="icon-pin"></i>
                                </div>
                                <div class="text">
                                    <p>33 Safia Zaghloul St, Alexandria, Egypt</p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="fas fa-phone-alt"></i>
                                </div>
                                <div class="text">
                                    <p><a href="tel:2034873086">+2 (03) 4873086</a></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="main-header-two__top-right">
                        <div class="main-header-two__social">
                            <!-- <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-facebook"></i></a>
                            <a href="#"><i class="fab fa-pinterest-p"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-header-two__bottom">
            <nav class="main-menu main-menu-two">
                <div class="main-menu-two__wrapper">
                    <div class="container">
                        <div class="main-menu-two__wrapper-inner">
                            <div class="main-menu-two__left">
                                <div class="main-menu-two__main-menu-box">
                                    <a href="#" class="mobile-nav__toggler"><i class="fa fa-bars"></i></a>
                                    <ul class="main-menu__list">
                                        <li class="dropdown current megamenu"><a href="#">Home</a></li>
                                        <li class="dropdown current megamenu"><a routerLink="/about">About</a></li>
                                        <li class="dropdown current megamenu"><a routerLink="/contact">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="stricky-header stricked-menu main-menu main-menu-two">
    <div class="sticky-header__content"></div><!-- /.sticky-header__content -->
</div><!-- /.stricky-header -->