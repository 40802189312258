  <!--Site Footer Start-->
  <footer class="site-footer">
    <div class="site-footer__top">
        <div class="container">
            <div class="site-footer__top-inner">
                <div class="site-footer-shape-1 float-bob-x"
                    style="background-image: url(assets/images/shapes/site-footer-shape-1.png);"></div>
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                        <div class="footer-widget__column footer-widget__about">
                            <div class="footer-widget__logo">
                                <a href="#"><img src="assets/images/resources/footer-logo.png"
                                        alt=""></a>
                            </div>
                            <div class="footer-widget__about-text-box">
                                <p class="footer-widget__about-text">The leading company in Cotton Trading 
                                    exporting, importing, gaining, established since 2004</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                        <div class="footer-widget__column footer-widget__Explore">
                            <div class="footer-widget__title-box">
                                <h3 class="footer-widget__title">Explore</h3>
                            </div>
                            <ul class="footer-widget__Explore-list list-unstyled">
                                <li><a href="#">Home</a></li>
                                <li><a routerLink="/about">About</a></li>
                                <li><a routerLink="/contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                        <div class="footer-widget__column footer-widget__Contact">
                            <div class="footer-widget__title-box" style="margin-bottom: 15px;">
                                <h3 class="footer-widget__title">Contact</h3>
                            </div>
                            <h4 class="footer-widget-contact__sub-title">Head Quarter</h4>
                            <ul class="footer-widget__Contact-list list-unstyled">
                                <li>
                                    <div class="icon">
                                        <span class="fas fa-phone-square-alt"></span>
                                    </div>
                                    <div class="text">
                                        <p><a href="tel:2034873086">+2 (03) 4873086</a></p>
                                    </div> 
                                    <div class="icon" style="margin-left: 20px;">
                                        <span class="fas fa-fax"></span>
                                    </div>
                                    <div class="text">
                                        <p><a href="tel:2034873087">+2 (03) 4873087</a></p>
                                    </div> 
                                </li>
                                <li>
                                    <div class="icon">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                    <div class="text">
                                        <p><a href="mailto:info@elekhlascotton.com">info@elekhlascotton.com</a></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="icon">
                                        <span class="icon-pin"></span>
                                    </div>
                                    <div class="text">
                                        <p> 
                                            <a href="https://goo.gl/maps/XaxVvXYeiQZ7VMeu6" target="_blank">33 Safia Zaghloul St, Alexandria, Egypt</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <h4 class="footer-widget-contact__sub-title mt-2">Branch</h4>
                            <ul class="footer-widget__Contact-list list-unstyled">
                                <li>
                                    <div class="icon">
                                        <span class="fas fa-phone-square-alt"></span>
                                    </div>
                                    <div class="text">
                                        <p><a href="tel:2034286514">+2 (03) 4286514</a></p>
                                    </div> 
                                    <div class="icon" style="margin-left: 20px;">
                                        <span class="fas fa-fax"></span>
                                    </div>
                                    <div class="text">
                                        <p><a href="tel:2034286508">+2 (03) 4286508</a></p>
                                    </div> 
                                </li>
                                <li>
                                    <div class="icon">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                    <div class="text">
                                        <p><a href="mailto:ekhlas.shipping@gmail.com">ekhlas.shipping@gmail.com</a></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="icon">
                                        <span class="icon-pin"></span>
                                    </div>
                                    <div class="text">
                                        <p> 
                                            <a href="https://goo.gl/maps/WEV5xNXP6wWG3syv5" target="_blank">111 Galal Eldesoky St , Alexandria, Egypt</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="site-footer__bottom">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="site-footer__bottom-inner">
                        <p class="site-footer__bottom-text">© Copyright 2022 by <a href="#">ElekhlasGroup.com</a></p>
                        <div class="site-footer__social">
                           
                        </div>
                        <div class="site-footer__bottom-scroll">
                            <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i
                                    class="icon-up-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--Site Footer End-->